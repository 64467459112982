import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ServiceHero from 'components/heros/serviceHero';
import SignUpCta from 'components/ctas/signUpCta';

const ServicePage = ({ data, heroImage, children }) => {
  const {
    markdownRemark: { frontmatter: {
      hero,
    } },
  } = data;

  return (
    <React.Fragment>
      <ServiceHero
        title={hero.title}
        subtitle={hero.subtitle}
      >
        {heroImage}
      </ServiceHero>

      {children}

      <SignUpCta />
    </React.Fragment>
  );
};

ServicePage.propTypes = {
  data: PropTypes.object.isRequired,
  heroImage: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ServicePage;

export const query = graphql`
  fragment ServicePageFragment on MarkdownRemark {
    ...LayoutFragment
  }
`;
