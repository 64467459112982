import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import { Box } from '@rebass/grid';
import renderHTML from 'react-render-html';

import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';

const Root = styled.div`
  text-align: center;
  min-height: 400px;
`;

const TextWrapper = styled(Box)`
  background-color: ${p => p.theme.primaryLight};
`;

const ImageWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    background-color: ${p => p.theme.primaryLight};
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

const ServiceHero = ({ title, subtitle, theme, children }) => {
  return (
    <Root>
      <TextWrapper pt={[10, 10, `100px`]}>
        <Container>
          <MaxWidth>
            <Heading rank="h1" style="h1" margin="0">{renderHTML(title)}</Heading>
            <Box pt={5} pb={[6, 6, 10]}>
              <Text style="h4" margin="0" color={theme.grey}>{renderHTML(subtitle)}</Text>
            </Box>
          </MaxWidth>
        </Container>
      </TextWrapper>

      <ImageWrapper>
        <Container>
          <MaxWidth>
            {children}
          </MaxWidth>
        </Container>
      </ImageWrapper>
    </Root>
  );
};

ServiceHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default withTheme(ServiceHero);

export const query = graphql`
  fragment ServiceHeroFragment on MarkdownRemark {
    frontmatter {
      hero {
        title
        subtitle
        image {
          src {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          alt
        }
      }
    }
  }
`;
