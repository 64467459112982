import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';

import Heading from 'components/typography/heading';
import Text from 'components/typography/text';
import Ratio16by9 from 'components/misc/ratio16by9';

const Root = styled(Markdown)`
  > *:first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 3em;
  }

  img,
  iframe {
    margin: 2em 0 !important;
  }
`;

const WysiwygArea = ({ children }) => {
  return (
    <Root
      options={{
        overrides: {
          h1: { component: Heading, props: { rank: `h1` } },
          h2: { component: Heading, props: { rank: `h2` } },
          h3: { component: Heading, props: { rank: `h3` } },
          h4: { component: Heading, props: { rank: `h4` } },
          h5: { component: Heading, props: { rank: `h5` } },
          h6: { component: Heading, props: { rank: `h6` } },
          p: { component: Text },
          iframe: { component: Ratio16by9 },
        },
      }}
    >
      {children}
    </Root>
  );
};

WysiwygArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default WysiwygArea;
