/* eslint jsx-a11y/iframe-has-title: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Ratio16by9 = ({ children, ...rest }) => {
  return (
    <Root><iframe {...rest} /></Root>
  );
};

Ratio16by9.propTypes = {
  children: PropTypes.object,
};

export default Ratio16by9;